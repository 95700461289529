@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


$screen-xs-min: 480px;

$screen-sm-min: 600px;

$screen-md-min: 960px;

$screen-lg-min: 1280px;

$screen-xl-min: 1920px;

@mixin xs {
  @media (max-width: #{$screen-xs-min}) {
      @content;
  }
}

@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
      @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
  height: 100%;
}

a {
  text-decoration: none;
}

input {
  outline: 0;
}

// for design purpose.
div {
  //border: 1px dotted pink;
}

.debug {

  //&--grid-container {
  //  border: 1px dashed rebeccapurple;
  //}
  //&--grid-item {
  //  border: 1px dotted blue;
  //}
  //&--magic {
  //  border: 1px dotted green;
  //}
  //&--grid-design {
  //  border: 1px dashed yellow;
  //}
  //&--item {
  //  border: 1px dashed lightgreen;
  //}
  //&--container {
  //  border: 1px dashed red;
  //}
  //&--content {
  //  border: 1px dotted deepskyblue;
  //}

}


.multiline {
  white-space: pre-line;
}

.slick-list{
  padding:0 0 0 0;
  *:focus {
    outline: 0;
    outline: none;
  }
}

.slide-slick{

}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

// for embedded hubspot form must target hs classes

#hubspotForm {
  margin-bottom: 200px;
}

.base-hubspot-form  {
  font-family: 'Roboto';
  label, legend {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.36px;
  @include xl {
    font-size: 22px;
    lineHeight: 1.3;
    letterSpacing: 0.44px;
    marginTop: 17px;
  }
  @include sm {
    font-size: 14px;
    lineHeight: 1.36;
    letterSpacing: 0.28px;
    marginTop: 7px;
  }

}

  input, select, textarea {
  background-color: white;
  border-radius: 6;
  box-shadow: 0px 2px 4px #00000040;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.36;
  margin-top: 20px;
  padding: 8px 0 9px 15px !important;
  border: none;
  @include sm {
    margin-top: 7px;
  }
  &::placeholder {
    color: #B3B3B3;
    font-family: 'Roboto';
  }
  @include xl {
    font-size: 22px;
    lineHeight: 1.3;
    letterSpacing: 0.44px;
    marginTop: 17px;
  }
  @include sm {
    font-size: 14px;
    lineHeight: 1.36;
    letterSpacing: 0.28px;
    marginTop: 7px;
  }
}
input {
  color: black;
  width: 100%;
}
select {
  color: #B3B3B3;
}
textarea {
  height: 200px;
  box-sizing: content-box;
}

.form-columns-1, .form-columns-2 {

  margin-top: 20px;
}
.hs-lastname {
  @include xs {
    margin-top: 20px;
  }
}
.hs-error-msg, .hs-main-font-element {
  color: #e53935;
  font-size: 12px;
}
.submitted-message {
  margin-bottom: -148px;
  color: #000000;
  font-size: 20px;
  padding: 25px 15px 25px 15px ;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(59,59,59,.2);
  -moz-box-shadow: 0px 3px 5px 0px rgba(59,59,59,.2);
  box-shadow: 0px 3px 5px 0px rgba(59,59,59,.2);
}
li::marker{
  color: transparent;
}
.hs-button {
  border-radius: 50px;
  color: white;
  height: 100%;
  width: 50%;
  font-size: 20px;
  box-shadow: 0px 3px 3px 0px #00000026;
  font-weight: 700;
  line-height: 0.7;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #26A7DF;
  padding: 6px 8px;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Roboto,sans-serif;
  border: 0;
  cursor: pointer;
  margin-top: 50px;
  display: inline-flex;
  outline: 0;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  @include sm {
    width: 100%;
  }
}
}
